$(function() {
  $('input.date-monthly').on('apply.daterangepicker', function (ev, picker) {    
    $('#daterange-error').addClass('d-none');
    $(this).closest('#form-monthly-update').find('.date-monthly').prop('disabled', true);
    $(this).prop('disabled', false);
    $(this).closest('#form-monthly-update').find('.btn-update-monthly').prop('disabled', false);
  });

  $(document).on('click', '.btn-update-monthly', function() {
    let date_at = $('#date_eq').val();
    let date_ranger = $('input[name=date_ranger]').val();
    
    if(date_at || date_ranger) {      
      $.ajax({
        url: '/monthly_updates/get_projects',
        method: 'get',
        data: { date_at: date_at, date_ranger: date_ranger },
        dateType: 'script'        
      });
    }
  })

  $(`input[name=date_ranger]`).daterangepicker({
    locale: ja_date_locale,
    autoUpdateInput: false,
    autoApply: true,
    allowEndDateFirst: true,
  });

  $(`input[name=date_ranger]`).on('apply.daterangepicker', function (ev, picker) {
    const moment = require('moment');
    var startDate = picker.startDate;
    var endDate = picker.endDate;
    $(this).val(startDate.format('YYYY/MM/DD') + ' ~ ' + endDate.format('YYYY/MM/DD'));
    if (startDate.format('MM') !== endDate.format('MM')) {
      $(this).closest('#form-monthly-update').find('.date-monthly').prop('disabled', true);
      $(this).prop('disabled', false);
      $(this).closest('#form-monthly-update').find('.btn-update-monthly').prop('disabled', true);
      $('#daterange-error').removeClass('d-none');
    } else {
      $('#daterange-error').addClass('d-none');
      $(this).closest('#form-monthly-update').find('.btn-update-monthly').prop('disabled', false);
    }
    // $(this).val(
    //   picker.startDate.format('YYYY/MM/DD') + ' ~ ' + picker.endDate.format('YYYY/MM/DD')
    // );
    var clearIcon = $(this).closest('.input-clearable').find('.clear-icon');
    clearIcon.css('display', this.value ? 'inline' : 'none');
  });

  $(`input[name=date_ranger]`).on('showCalendar.daterangepicker', function(ev, picker) {
    const moment = require('moment');

    var startDate = picker.startDate;
    var endDate = picker.endDate;

    if (picker.chosenLabel === "Custom Range") {
      var minEndDate = moment(startDate).startOf('month');
      var maxEndDate = moment(startDate).endOf('month');

      // picker.maxDate = maxEndDate;
      // picker.minDate = minEndDate;
      picker.updateView();
    }

  });
})