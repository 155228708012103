$(function () {
  function initDatePicker(id, maxDate, minDate, reset = false) {
    if (reset) {
      $(`${id}`).val('');
    }
    $(`${id}`).daterangepicker({
      locale: ja_date_locale,
      singleDatePicker: true,
      autoUpdateInput: false,
      autoApply: true,
      maxDate,
      minDate,
    });

    $(`${id}`).on('apply.daterangepicker', function (_ev, picker) {
      const value = picker.startDate.format('YYYY/MM/DD');
      switch (id) {
        case '#order_sheet_actual_delivery_at':
          initDatePicker('#order_sheet_actual_acceptance_at', null, value, true);
          $('#order_sheet_completed_delivery_at').val('');
          var clearIcon = $('#order_sheet_completed_delivery_at').closest('.input-clearable').find('.clear-icon');
          clearIcon.css('display', this.value ? 'inline' : 'none');
          break;
        case '#order_sheet_actual_acceptance_at':
          $('#order_sheet_completed_delivery_at').val('');
          var clearIcon = $('#order_sheet_completed_delivery_at').closest('.input-clearable').find('.clear-icon');
          clearIcon.css('display', this.value ? 'inline' : 'none');
          break;
        default:
          break;
      }

      $(this).val(value);
    });

    $(`${id}`).on('cancel.daterangepicker', function () {
      $(this).val('');
    });
    loadProductData();
    $(document).on('cocoon:after-insert', '.multi-field-wrapper', function (e, insertedItem) {
      let $wrapNestedElm = $(insertedItem).closest('.nested-fields');
      $wrapNestedElm.find('.form-select.select2').select2({
        theme: 'bootstrap',
        placeholder: '選択してください',
        allowClear: true,
        language: {
          noResults: function () {
            return '見つかりません。';
          },
        },
      });
      loadProductData();
    });
  }

  function loadProductData() {
    $('.js-oder-sheet-product-id').on('change', function () {
      var productId = $(this).val();
      var $estimateItem = $(this).closest('.estimate-item-fields');
      var $elm = $(this).closest('.nested-fields');
      if (productId) {
        $.ajax({
          url: `/products/${productId}/load_data`,
          method: 'GET',
          success: function (res) {
            var product = res.product;
            var price = product.price || 0;
            $elm.find('.js-price').val(price);
            $(".js-price").toArray().forEach(function(field) {
              new Cleave(field, {
                numeral: true,
                numeralThousandsGroupStyle: 'thousand'
              });
            });
          },
        });
      }
    });
  }

  $('.order-sheet-project-id').on('change', function () {
    var projectId = $(this).val();
    // $('.order-sheet-worker-ids')
    //   .html('')
    //   .select2({ data: [{ id: '', text: '' }] });
    $('.order-sheet-approved-worker-id')
      .html('')
      .select2({ data: [{ id: '', text: '' }] });

    if (projectId) {
      $.ajax({
        url: '/estimate_sheets/load_workers',
        method: 'GET',
        data: { project_id: projectId },
        success: function (res) {
          var workers = _.map(res.workers, function (worker) {
            return { id: worker.id, text: worker.fullname };
          });
          // $('.order-sheet-worker-ids').html('').select2({ data: workers });
          // $('.order-sheet-worker-ids').prop('disabled', false);

          var managers = _.map(res.managers, function (worker) {
            return { id: worker.id, text: worker.fullname };
          });
          $('.order-sheet-approved-worker-id').html('').select2({ data: managers });
          $('.order-sheet-approved-worker-id').prop('disabled', false);

          // resetSelect2($('.order-sheet-worker-ids'));
          resetSelect2($('.order-sheet-approved-worker-id'));
          // $('.order-sheet-company-id').val(res.company_id).trigger('change');
          // $('.order-sheet-worker-ids').trigger('change');
          $('.order-sheet-approved-worker-id').trigger('change');
        },
      });

      $.ajax({
        url: '/order_sheets/load_ordereds',
        method: 'GET',
        dataType: 'script',
        data: { project_id: projectId }    
      })
    } 
    // else {
    //   $('.order-sheet-worker-ids').prop('disabled', true);
    // }
  });

  $(document).on('submit', '.form-order-sheet', function (e) {
    e.preventDefault();
    Array.from(['.js-amount', '.js-price', '.js-sell-price']).forEach((id) => {
      $(id)
        .toArray()
        .forEach(function (field) {
          const cleave = new Cleave(field, {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          });
          const val = cleave.getRawValue();
          $(field).val(val);
        });
    });
    e.currentTarget.submit();
  });

  function resetSelect2($elm) {
    $($elm).select2({
      theme: 'bootstrap',
      placeholder: '選択してください',
      allowClear: true,
      language: {
        noResults: function () {
          return '見つかりません。';
        },
      },
    });
  }

  [
    '#order_sheet_ordered_at',
    '#order_sheet_estimated_delivery_at',
    '#order_sheet_completed_delivery_at',
    '#order_sheet_actual_delivery_at',
    '#order_sheet_payment_at',
    '#order_sheet_estimated_acceptance_at',
    '#order_sheet_actual_acceptance_at',
  ].forEach((id) => {
    initDatePicker(id);
  });

  $('span[data-order-sheet-id]').on('click', function () {
    $('body').loading({
      message: '書類の取得...',
    });
    const id = this.getAttribute('data-order-sheet-id')
    $.ajax({
      method: 'PUT',
      url: `/order_sheets/${id}/update_status`,
    });
    setTimeout(() => {
      $('body').loading('destroy');
    }, 3000);
  });
});

document.addEventListener("DOMContentLoaded", function() {
  const inputs = document.querySelectorAll('.input-clearable');

  inputs.forEach(function(inputGroup) {
    const clearIcon = inputGroup.querySelector('.clear-icon');
    const datepickerInput = inputGroup.querySelector('.datepicker-js');

    clearIcon.addEventListener('click', function() {
      if(datepickerInput) {
        datepickerInput.value = '';
      }
      $(this).css('display', 'none');
      $(this).closest('.input-clearable').find('input').val('');
      if(datepickerInput) {
        datepickerInput.dispatchEvent(new Event('change'));
      }      
    });
    if(datepickerInput) {
      datepickerInput.addEventListener('change', function() {
        clearIcon.style.display = datepickerInput.value ? 'inline' : 'none';
      });
    }    
  });
});

$(document).ready(function() {
  $('.datepicker-js').on('apply.daterangepicker', function(ev, picker) {    
    $(this).val(picker.startDate.format('YYYY/MM/DD'));
    var clearIcon = $(this).closest('.input-clearable').find('.clear-icon');
    clearIcon.css('display', this.value ? 'inline' : 'none');
  });

  $(document).on('click', '.clear-icon', function() {
    const datepickerInput = document.querySelector('.datepicker-js');
    if(datepickerInput) {
      datepickerInput.value = '';
    }
    $(this).css('display', 'none');
    $(this).closest('.input-clearable').find('input').val('');
    if(datepickerInput) {
      datepickerInput.dispatchEvent(new Event('change'));
    }
    if($(this).closest('#form-monthly-update')) {
      $(this).closest('#form-monthly-update').find('.date-monthly').prop('disabled', false);
      $(this).closest('#form-monthly-update').find('.btn-update-monthly').prop('disabled', true);
    }
  })
})

$(document).ready(function() {
  function calculateTotal() {
    let total = 0;

    $('.order-items-list').find('.nested-fields:visible').each(function() {
      let price = parseFloat($(this).find('.js-sell-price').first().val().replace(/,/g, '') || 0);
      let discount = parseFloat($(this).find('.money-field').val().replace(/,/g, '')) || 0;
      let isTaxChecked = $(this).find('.js-is_freetax').is(':checked');      
      let rowTotal = price - discount;

      if (!isTaxChecked) {
        rowTotal *= 1.10;
      }

      total += rowTotal;
    });

    $('#total_amount').text("¥" + (Math.round(total) || 0).toLocaleString());
  }

  $(document).on('input', '.js-sell-price, .money-field', calculateTotal);
  $(document).on('change', '.js-sell-price, .money-field', calculateTotal);
  $(document).on('change', '.js-is_freetax', calculateTotal);
  $(document).on('click', '.js-btn-ok', function(){ 
    if($(location).attr('href').split('/').includes('order_sheets')) {
      calculateTotal();
    }
  });  
});
