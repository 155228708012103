$(document).ready(function () {
  $(document).on('click', function (event) {
    const $target = $(event.target);
    if (!$target.closest('.modal-dialog').length && $('.modal-dialog').is(':visible')) {
      resetCreateModal();
    }
  });

  $('.js-cancel-create-expense-approval-role').on('click', function () {
    $('#create-expense-approval-role').modal('hide');
    resetCreateModal();
  });

  $(document).on('click', '.js-create-expense-approval-role', function () {
    const createAction = $('#create-expense-approval-role').data('create-action');
    $('#create-expense-approval-role .new_expense_approval_role').attr('action', createAction);
    $('#create-expense-approval-role .new_expense_approval_role').attr('method', 'post');
    $('#create-expense-approval-role .modal-title').text('役職追加');

    $('#create-expense-approval-role').modal('show');
  });

  function resetCreateModal() {
    $('.help-block').remove();
    $('.has-error').removeClass('has-error');
    $('#title_expense_approval_role').val('');
    $('#url_expense_approval_role').val('');
    // $('input[name=_method]').remove();
    $('#position_header').val('');
  }
});
