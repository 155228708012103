
function control_company_closing_day() {
  var value = $('#q_company_closing_day').val();
  var status = $('#q_status_eq').val();
  if (value != undefined && value.length > 0 && status == 'inprogress') {
    $("#show_create_bill").prop('disabled', false);
  } else {
    $("#show_create_bill").prop('disabled', true);
  }
}

$(document).ready(function () {
  $("#q_payment_period_months_eq").on('apply.daterangepicker', function () {
    var month = $(this).val().split('/')[1];
    var days = new Date(new Date().getFullYear(), month, 0).getDate();
    $("#billing_start_month").html('');

    var options = []
    for (var i = 1; i <= days; i++) {
      options.push({ id: i, text: i })
    }
    var monthSelected = $("#q_company_closing_day").val();
    $("#modal-create-bill .modal-title").html("<b>一括請求作成</b>" + "(" + $(this).val() + ")");

    $("#q_company_closing_day").empty().select2({
      width: '100%',
      theme: 'bootstrap',
      placeholder: '選択してください',
      allowClear: true,
      data: options,
      language: {
        noResults: function () {
          return '見つかりません。';
        },
      },
    }).val(monthSelected).trigger('change');
    control_company_closing_day()
  });
});

$(document).on('click', "#show_all_project_continue", function () {
  $("#project-continue-form .js-is-expired-true").removeClass('d-none');
})
$(document).on('change', "#q_company_closing_day", function () {
  control_company_closing_day()
})
$(document).on('change', "#q_status_eq", function () {
  control_company_closing_day()
})

$(document).on('change', "#contract_start_at", function () {
  var value = $(this).val();
  $("#billing_start_month").val(value);
})

function updateIgnoreIds() {
  var project_continue_ids = [];
  $("input[name='select_project[]']:checked").each(function () {
    project_continue_ids.push($(this).val());
  })
  $('#ignore_ids').val(project_continue_ids)
  $("#delete_project_continue").prop('disabled', project_continue_ids.length == 0);
}

$(document).on('change', "input[name='select_project[]']", function () {
  updateIgnoreIds()
  if(!this.checked) {
    $('#select_all_project').prop('checked', false);
  }
  if ($("input[name='select_project[]']").length == $("input[name='select_project[]']:checked").length) {
    $('#select_all_project').prop('checked', true);
  }
})

$(document).on('change', "#select_all_project", function () {
  var checked = $(this).is(':checked');
  $("input[name='select_project[]']").prop('checked', checked);
  updateIgnoreIds()
})

$(document).on('change', "#modal_select_all_project", function () {
  var checked = $(this).is(':checked');
  $("input[name='modal_select_project[]']").prop('checked', checked);
})

$(document).on('change', "input[name='modal_select_project[]']", function () {
  if (!$(this).is(':checked')) {
    $("#modal_select_all_project").prop('checked', false);
  } else if ($("input[name='modal_select_project[]']").length == $("input[name='modal_select_project[]']:checked").length) {
    $("#modal_select_all_project").prop('checked', true);
  }
})

$(document).on('click', '#delete_project_continue', function () {
  var deleted = $("#delete_project_continue").data('deleted');
  if(deleted) {
    $("#delete_project_continue").data('deleted', false);
    $('#ignore_ids').val('');
    $('#delete_project_continue').html('一括削除')
    $('#delete_project_continue').prop('disabled', true);
  } else {
    $('#delete_project_continue').html('一括削除解除')
    $("#delete_project_continue").data('deleted', true);
  }

  $('#project_continue_filter').attr('data-remote', 'true');
  $('#project_continue_filter .btn-submit').trigger('click');
})

$(document).on('click', '#show_create_bill', function () {
  var project_continue_ids = [];
  $('#modal_select_all_project').prop('checked', false);
  $('#modal-create-bill .body-modal-create-payment').html('');
  $('#modal_loading').show()
  $("input[name='select_project[]']:checked").each(function () {
    project_continue_ids.push($(this).val());
  })

  $.ajax({
    url: '/project_continues/load_modal_bill_choose_project?' + $("#project_continue_filter").serialize(),
    type: 'GET',
    // data: { project_continue_ids: project_continue_ids },
    success: function (data) {
      $('#modal_loading').hide()
      $('#modal-create-bill .body-modal-create-payment').html(data);
    }
  })
  $('#modal-create-bill').modal('show');
})

function callBulkCreateBillSheet() {
  var project_continue_ids = []
  $("input[name='modal_select_project[]']:checked").each(function () {
    project_continue_ids.push($(this).val());
  })
  var expired_at = $("#q_payment_period_months_eq").val() + '/' + $("#q_company_closing_day").val();

  $.ajax({
    url: '/project_continues/bulk_create_bill_sheet',
    type: 'POST',
    data: { project_continue_ids: project_continue_ids, expired_at: expired_at },
    success: function (data) {
      window.location.href = '/bill_sheets'
    }
  })
}

$(document).on('click', '#confirmed_bulk_create_pr', function () {
  callBulkCreateBillSheet();
})

$(document).on('click', '#mass-create-pr', function () {
  var project_continue_ids = [];
  $("input[data-exist='true']:checked").each(function () {
    project_continue_ids.push($(this).val());
  })

  if ($("input[name='modal_select_project[]']:checked").length == 0) {
    return
  }

  if (project_continue_ids.length == 0) {
    callBulkCreateBillSheet()
    return;
  }


  if (project_continue_ids.length == 0) {
    callBulkCreateBillSheet()
    return;
  }

  var expired_at = $("#q_payment_period_months_eq").val() + '/' + $("#q_company_closing_day").val();

  $('#modal-confirm .body-modal-create-bill-confirm').html('');
  $.ajax({
    url: '/project_continues/load_row_modal_confirm',
    type: 'GET',
    data: { project_continue_ids: project_continue_ids, expired_at: expired_at },
    success: function (data) {
      $('#modal-confirm .body-modal-create-bill-confirm').html(data);
      $('#modal-create-bill').modal('hide');
      $('#modal-confirm').modal('show');
    }
  })
})
