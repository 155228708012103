$(document).ready(function () {
  const zipInput = document.getElementById("zipcode");
  if(zipInput) {
    setMarkInput();
  }

  $('.edit-lease-rate-field').on('click', function () {
    const nestedField = $(this).closest('.nested-fields')
    nestedField.find('.show-lease-rate').empty();
    nestedField.find('.edit-lease-rate').removeClass('d-none');
  });

  function setMarkInput() {
    Inputmask({"mask": "999-9999"}).mask(zipInput);
  }
});
