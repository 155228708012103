window.el_date_locale = {
    "format": "YYYY/MM/DD",
    "separator": " ~ ",
    "autoApply": "true",
    "fromLabel": "From",
    "toLabel": "To",
    "customRangeLabel": "Custom",
    "daysOfWeek": [
        "Su",
        "Mo",
        "Tu",
        "We",
        "Th",
        "Fr",
        "Sa"
    ],
    "monthNames": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ],
    "firstDay": 1,
    "cancelLabel": 'Clear'
}

window.ja_date_locale = {
    "format": "YYYY/MM/DD",
    "separator": " ~ ",
    "autoApply": "true",
    "fromLabel": "から",
    "toLabel": "に",
    "customRangeLabel": "カスタム",
    "daysOfWeek": [
        "日",
        "月",
        "火",
        "水",
        "木",
        "金",
        "土"
    ],
    "monthNames": [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月"
    ],
    "firstDay": 1,
    cancelLabel: 'クリア'
}

window.ja_date_locale_month_year = {
    "format": "YYYY/MM",
    "separator": " ~ ",
    "fromLabel": "から",
    "toLabel": "に",
    "customRangeLabel": "カスタム",
    "daysOfWeek": [
        "日",
        "月",
        "火",
        "水",
        "木",
        "金",
        "土"
    ],
    "monthNames": [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月"
    ],
    "firstDay": 1,
    cancelLabel: 'クリア'
}