$(document).on('click', '.btn-save-model', function (e) {
  let $nestedElm = $(e.target).closest('.nested-fields');

  $nestedElm.find('.wrap-model-name').addClass('d-none');
  $nestedElm.find('.wrap-model-code').addClass('d-none');
  $nestedElm.find('.plain-text.name').removeClass('d-none').text($nestedElm.find('.wrap-model-name input').val());
  $nestedElm.find('.plain-text.code').removeClass('d-none').text($nestedElm.find('.wrap-model-code input').val());
  $(this).addClass('d-none');
  $nestedElm.find('.model-icons').removeClass('d-none');
})


$(document).on('click', '.js-edit-model', function (e) {
  let $nestedElm = $(e.target).closest('.nested-fields');

  $nestedElm.find('.wrap-model-name').removeClass('d-none');
  $nestedElm.find('.wrap-model-code').removeClass('d-none');
  $nestedElm.find('.btn-save-model').removeClass('d-none');
  $nestedElm.find('.plain-text.name').addClass('d-none');
  $nestedElm.find('.plain-text.code').addClass('d-none');
  $nestedElm.find('.model-icons').addClass('d-none');
})
