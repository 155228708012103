$(function() {
  bindOnChangeProduct();
  $(document).on("cocoon:after-insert", "#form-template-items", function(e, insertedItem) {
    let $wrapNestedElm = $(insertedItem).closest(".nested-fields") ;
    bindOnchangeSellPrice($wrapNestedElm);
    triggerInlyInteger();
    bindOnChangeProduct();
  });

  $.each($("#form-template-items").find(".nested-fields"), function(idx, $elm) {
    bindOnchangeSellPrice($elm);
    triggerInlyInteger();
  })

  function calculateCostPrice($wrapNestedElm) {
    var sellPrice = _.toInteger($($wrapNestedElm).find(".js-price").val().replace(',', '')) * _.toInteger($($wrapNestedElm).find(".js-amount").val().replace(',', ''));
    $($wrapNestedElm).find(".js-sell-price").val(sellPrice);
  }

  function bindOnchangeSellPrice($wrapNestedElm) {
    $($wrapNestedElm).find(".js-amount, .js-price").on("keyup keydown keypress paste", function() {
      calculateCostPrice($(this).closest(".nested-fields"));
    })
  }

  $(document).on('change', '#form-template-items .js-amount, #form-template-items .js-price', function() {
    calculateCostPrice($(this).closest(".nested-fields"));
  })

  function triggerInlyInteger() {
    $(".js-amount, .js-cost-price, .js-price, .js-sell-price, .js-discount").on("keypress", function(evt) {
      validateOnlyInteger(evt);
    });
  }

  function bindOnChangeProduct() {
    $(".js-product-id").on("change", function() {
      var productId = $(this).val();
      var $elm = $(this).closest(".nested-fields");
      if (productId) {
        $.ajax({
          url: `/products/${productId}/load_data`,
          method: 'GET',
          success: function (res) {
            var price = res.product.price;
            $elm.find(".js-price").val(price);
          }
        });
      }
    })
  }

  function validateOnlyInteger(evt) {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === 'paste') {
        key = event.clipboardData.getData('text/plain');
    } else {
    // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    var regex = /[0-9０-９]/;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  }
})
