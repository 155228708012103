$(document).ready(function () {
  $('.click-trigger-checkbox').on('click', function(){
    condition = $(this).parent().find('input').prop('checked')
    $(this).parent().find('input').prop('checked', !condition)
  })

  $('.save-partner-type').on('click', function(){
    const checkboxes = $('input.partner_type:checked');
    let partnerTypeIds = [];

    _.forEach(checkboxes, function(el) {
      partnerTypeIds.push(el.value);
    });

    $('input[name="partner_type_ids"]').val(partnerTypeIds)
  })
})
