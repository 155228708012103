// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

Rails.start();
ActiveStorage.start();

import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap';
import 'bootstrap/dist/css/bootstrap';
import './daterangepicker.min.js';
import './print.min.js';
import './jquery.loading.min.js';
import './cleave.min.js';
import '../stylesheets/application';

import * as bootstrap from 'bootstrap';

const images = require.context('../images', true);
import Inputmask from 'inputmask';

import './common.js';
import './custom.js';
import './popover_custom.js';
import './pages/products/create.js';
import './pages/header-links/index.js';
import './pages/expense-approval-roles/index.js';
import './pages/daily_report.js';
import './pages/maker.js';
import './pages/expenses/index.js';
import './pages/partner_company.js';
import './pages/client_company.js';
import './pages/order_sheets.js';
import './pages/estimate_sheet.js';
import './pages/company.js';
import './pages/bank.js';
import './pages/project.js';
import './pages/sheets.js';
import './pages/template.js';
import './pages/lease-companies/edit_lease_rate.js';
import './pages/role_responsibility.js';
import './pages/bill_sheet.js';
import './pages/problem_client_company.js';
import './pages/project_continue.js';
import './pages/monthly_update.js';

import 'lodash';

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

require('@nathanvda/cocoon');
import 'select2';
require('select2/dist/css/select2');
require('select2-bootstrap-theme/dist/select2-bootstrap');

var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl);
});

import './trix.js';
require('@rails/actiontext');
require('./nested_forms/addFields');
require('./nested_forms/removeFields');

import 'controllers';
