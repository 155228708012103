$(document).ready(function () {
  $('.js-approve-all').on('click', function () {
    var checkedValues = $('input:checkbox:checked').map(function() {
      return this.name;
    }).get();

    $.ajax({
      url: '/expense_judged_workers/create_multi',
      method: 'POST',
      data: { expense_ids: checkedValues },
      success: function (res) {
        window.location = '/expenses'
      },
    });
  });

  $(document).on('click', '.close-worker', function () {
    var expense_worker = $(this).closest(`.${this.name}`)
    expense_worker.find(".expense-worker").empty()
    expense_worker.find(".btn-settings").removeClass('d-none')
  });

  $(document).on('click', '.close-project', function () {
    var expense_project = $(this).closest(`.${this.name}`)
    expense_project.find(".expense-project").empty()
    expense_project.find(".btn-settings").removeClass('d-none')
  });
})
