$(document).ready(function () {
  const init_select2_multi = (selector) => {
    $(selector).attr('multiple', 'multiple');
    $(selector).select2({
      multiple: true,
      theme: 'bootstrap',
      placeholder: '選択してください',
      allowClear: true,
      language: {
        noResults: function () {
          return '見つかりません。';
        },
      },
    });
  };

  init_select2_multi('#permission_ids');
})
