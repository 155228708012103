function resetErrors($nestedEditableRow) {
  $nestedEditableRow.find('.has-error').removeClass('has-error');
  $nestedEditableRow.find('.help-block').remove();
}

function handleResponse(res, $nestedEditableRow, dailyReportId) {
  if (res.status) {
    window.location.href = `/daily_reports/${dailyReportId}}/edit`;
  } else {
    _.each(res.errors, function (error, key) {
      let $inputElement = $nestedEditableRow.find(`.${key}`);
      if (_.includes(['project_id', 'activity_type_id'], key)) {
        $inputElement = $inputElement.closest('.form-select2').find('.select2-container');
      }
      if ($inputElement) {
        $("<span class='help-block'>" + error[0] + "</span>").insertAfter($($inputElement));
        $inputElement.addClass('has-error');
      }
    });
  }
}

$(document).ready(function () {
  $('.js-edit-activity').on('click', function() {
    let activityId = $(this).closest('.row-activity').data('id');
    $(this).closest('.row-activity').addClass('d-none');
    let $nestedEditableRow = $(`.nested-fields.row-${activityId}`);
    $nestedEditableRow.removeClass('d-none');
    $nestedEditableRow.find('.btn-save-activity').addClass('d-none');
    $nestedEditableRow.find('.group-edit-activity').removeClass('d-none');
  });

  $('.js-cancel-edit-activity').on('click', function() {
    let $nestedEditableRow = $(this).closest('.nested-fields');
    let activityId = $nestedEditableRow.data('id');
    $nestedEditableRow.addClass('d-none');
    $(`.fixed-text.row-${activityId}`).removeClass('d-none');
  });

  $('.btn-update-activity').on('click', function() {
    let $nestedEditableRow = $(this).closest('.nested-fields');
    resetErrors($nestedEditableRow);
    let dailyReportId = $('.activity-section-wrap').data('daily-report-id');
    let params = {};
    let activityId = $nestedEditableRow.data('id');
    _.each(['project_id', 'hours', 'minuts', 'activity_type_id', 'content'], function(field) {
      params[field] = $nestedEditableRow.find(`.${field}`).val();
    });
    $.ajax({
      url: `/daily_reports/${dailyReportId}/activities/${activityId}`,
      method: 'PUT',
      data: {activity: params},
      success: function (res) {
        handleResponse(res, $nestedEditableRow, dailyReportId);
      },
    });
  });


  $(document).on('cocoon:after-insert', '#activities_form, #new-daily-report-form', function(e, insertedItem) {
    let $wrapNestedElm = $(insertedItem).closest('.nested-fields') ;
    $wrapNestedElm.find('.select2').select2({
      theme: 'bootstrap',
      placeholder: '選択してください',
      allowClear: true,
      language: {
        noResults: function () {
          return '見つかりません。';
        },
      },
    });

    $(document).on('click','.btn-save-activity', function() {
      let $nestedEditableRow = $(this).closest('.nested-fields');
      resetErrors($nestedEditableRow);
      let dailyReportId = $('.activity-section-wrap').data('daily-report-id');
      let params = {};
      _.each(['project_id', 'hours', 'minuts', 'activity_type_id', 'content'], function(field) {
        params[field] = $nestedEditableRow.find(`.${field}`).val();
      });

      $.ajax({
        url: `/daily_reports/${dailyReportId}/activities`,
        method: 'POST',
        data: {activity: params},
        success: function (res) {
          handleResponse(res, $nestedEditableRow, dailyReportId);
        },
      });
    });
  });
});

$(document).on('click', '.btn-save-activity', function () {
  let $nestedEditableRow = $(this).closest('.nested-fields');
  resetErrors($nestedEditableRow);
  let dailyReportId = $('.activity-section-wrap').data('daily-report-id');
  let params = {};
  _.each(['project_id', 'hours', 'minuts', 'activity_type_id', 'content'], function (field) {
    params[field] = $nestedEditableRow.find(`.${field}`).val();
  });

  $.ajax({
    url: `/daily_reports/${dailyReportId}/activities`,
    method: 'POST',
    data: { activity: params },
    success: function (res) {
      handleResponse(res, $nestedEditableRow, dailyReportId);
    },
  });
});
