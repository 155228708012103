$(document).ready(function () {
  const arrDiv = ['.amount', '.price', '.number_of_days', '.number_of_people_per_day']
  _.forEach(arrDiv, function(item) {
    $(document).on("keyup", item, function() {
      this.value = this.value.replace(/[^0-9]/g, '');
      const amount = $(this).closest(".parent-fields").find(".amount").val()
      const price = $(this).closest(".parent-fields").find(".price").val()
      const numberOfDays = $(this).closest(".parent-fields").find(".number_of_days").val()
      const numberOfPeople = $(this).closest(".parent-fields").find(".number_of_people_per_day").val()

      if(numberOfPeople > 0 && numberOfDays > 0) {
        total = numberOfPeople * numberOfDays * price * amount
      } else {
        total = price * amount
      }

      $(this).closest(".parent-fields").find(".fee").val(total)
    });
  });

  let searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('interest_rate_search')) {
    $('#interest_search_type').val(searchParams.get('interest_rate_search')).change();
  }
})
