$(document).ready(function () {
  const productType = $('#product_type');
  const nestedObjectForm = $('#nested-object');
  const nestedExecutionForm = $('#nested-execution');
  const productForm = $('#nested-service');
  const nestedRsForm = $('#nested-rs');
  const nestedMaintenanceForm = $('#nested-maintenance');
  const nestedStock = $('.nested-stock');

  productType.on('change', function () {
    switch ($(this).val()) {
      case 'object': {
        nestedObjectForm.removeClass('d-none');
        nestedStock.removeClass('d-none');
        nestedExecutionForm.addClass('d-none');
        nestedRsForm.addClass('d-none');
        nestedMaintenanceForm.addClass('d-none');
        productForm.addClass('d-none');
        break;
      }
      case 'execution': {
        nestedExecutionForm.removeClass('d-none');
        nestedObjectForm.addClass('d-none');
        nestedRsForm.addClass('d-none');
        nestedStock.addClass('d-none');
        nestedMaintenanceForm.addClass('d-none');
        productForm.addClass('d-none');
        break;
      }
      case 'rs': {
        nestedExecutionForm.addClass('d-none');
        nestedObjectForm.addClass('d-none');
        nestedMaintenanceForm.addClass('d-none');
        productForm.addClass('d-none');
        nestedStock.addClass('d-none');
        nestedRsForm.removeClass('d-none');
        break;
      }
      case 'maintenance': {
        nestedExecutionForm.addClass('d-none');
        nestedObjectForm.addClass('d-none');
        nestedRsForm.addClass('d-none');
        productForm.addClass('d-none');
        nestedStock.addClass('d-none');
        nestedMaintenanceForm.removeClass('d-none');
        break;
      }
      case 'product': {
        nestedExecutionForm.addClass('d-none');
        nestedObjectForm.addClass('d-none');
        nestedRsForm.addClass('d-none');
        nestedStock.addClass('d-none');
        nestedMaintenanceForm.addClass('d-none');
        productForm.removeClass('d-none');
        break;
      }
      default: {
        nestedRsForm.addClass('d-none');
        nestedMaintenanceForm.addClass('d-none');
        nestedExecutionForm.addClass('d-none');
        nestedObjectForm.addClass('d-none');
        productForm.addClass('d-none');
        nestedStock.addClass('d-none');
      }
    }
  });

  const init_select2 = (selector) => {
    $(selector).select2({
      theme: 'bootstrap',
      placeholder: '選択してください',
      allowClear: true,
      language: {
        noResults: function () {
          return '見つかりません。';
        },
      },
    });
  };

  init_select2('.select2');

  $('.multi-field-wrapper').on('cocoon:after-insert', function (_, row) {
    field = $(row).find('.select2');
    init_select2(field);
  });

  $('.js-select-maker').on('change', function() {
    $('.js-select-model').find('option').remove();
    $('.js-select-type').find('option').remove();
    let makerId = $(this).val();

    if (!!makerId) {
      $('.js-select-model').prop('disabled', false);

      $.ajax({
        url: `/makers/${makerId}/load_models`,
        method: 'GET',
        success: function (res) {
          $.each(res.models, function (i, item) {
            $('.js-select-model').append($('<option>', {
              value: item.id,
              text : item.name
            }));
            $('.js-select-type').append($('<option>', {
              value: item.object_lease_type_id,
              text : item.object_lease_type_name
            }));
          });
          let maker_id = $(".js-select-maker").find(":selected").text();
          let object_lease_type_id = $('.js-select-type').find("option:first-child").text();
          let model_id = $(".js-select-model").find("option:first-child").text();
          $("#name").val(maker_id+object_lease_type_id+model_id);
        },
      });
    } else {
      $('.js-select-model').prop('disabled', true);
    }
  })
});
