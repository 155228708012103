$(function () {
  $(document).on('change', '#check_all', function () {
    if ($(this).is(':checked')) {
      $('.tbody-bill-sheet').find('input[type=checkbox]').prop('checked', true);
    } else {
      $('.tbody-bill-sheet').find('input[type=checkbox]').prop('checked', false);
    }
    check_checked();
  })

  $(document).on('change', '.check_box_bill', function () {
    if (!$(this).is(':checked')) {
      $('#check_all').prop('checked', false);
    } else {
      if ($('.check_box_bill:checked').length == $('.check_box_bill').length) {
        $('#check_all').prop('checked', true);
      }
    }
    check_checked();
  })

  $(document).on('click', '#show_print_list', function () {
    var list = $("input[name='check_bill[]']:checked").map(function () {
      return this.value;
    }).get();
    if (list.length == 0) return;
    reset_radio();
    $('#print_list').find('#bill_sheet_ids').val(list);
    $('#print_list').modal('show');
  })

  $(document).on('click', '.print_billing', function () {
    var id = $(this).data('id');
    reset_radio();
    $('#print_bill').find('#bill_sheet_ids').val(id);
    $('#print_bill').modal('show');
  })

  function update_status_print(bill_sheet_id) {
    $.ajax({
      url: `/bill_sheets/${bill_sheet_id}/status_print`,
      method: 'POST',
    });
  }

  function intevarCheckStatus(time, bill_sheet_id) {
    var count = 0;
    var setIntever = setInterval(function () {
      count++;
      if (count > time) {
        clearInterval(setIntever);
      }
      update_status_print(bill_sheet_id);
    }, 1000);
  }

  $(document).on('click', '#submit-export', function () {
    $(this).closest('form').submit();
    var bill_sheet_id = $(this).closest('form').find('#bill_sheet_ids').val()

    setTimeout(() => {
      intevarCheckStatus(3, bill_sheet_id);
      $('#print_list').modal('hide');
      $('#print_bill').modal('hide');
    }, 2000);
  })

  function reset_radio() {
    $("input[name=pdf_type][value=client]").prop('checked', 'checked');
    $("input[name=kagami_type][value=1]").prop('checked', 'checked');
    $("input[name=format_type][value=1]").prop('checked', 'checked');
  }

  function check_checked() {
    if ($('.check_box_bill').is(":checked")) {
      $('#show_print_list').find('span').removeClass('background-gray');
    } else {
      $('#show_print_list').find('span').addClass('background-gray');
    }
  }
})
