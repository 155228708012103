$(document).ready(function() {
  $(document).on('click','.btn-add-problem', function() {
    $('#title-form-problem').html("障害登録");
    $.ajax({
      url: `/problem_client_companies/new`,
      method: 'GET',
      dataType: 'script'
    })
  })

  $(document).on('click', '.btn-edit-problem', function() {
    $('#title-form-problem').html("障害編集");
    $.ajax({
      url: `/problem_client_companies/${$(this).data('id')}/edit`,
      method: 'GET',
      dataType: 'script'
    })
  })

  $(document).on('submit', '#form-problem', function(e) {
    e.preventDefault();
    $.ajax({
      url: $(this).attr('action'),
      type: 'POST',
      data: $(this).serialize(),
      dataType: 'script'
    });
  })
})