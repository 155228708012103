$(document).ready(function () {
  $('.js-cancel-create-header-link').on('click', function () {
    $('#create-header-link').modal('hide');
    resetCreateModal();
  });

  $(document).on('click', '.js-create-header-link', function () {
    const createAction = $('#create-header-link').data('create-action');
    $('#create-header-link .new_header_link').attr('action', createAction);
    $('#create-header-link .new_header_link').attr('method', 'post');
    $('#create-header-link .modal-title').text('新規作成');

    $('#create-header-link').modal('show');
  });

  $(document).on('click', '.js-edit-header-link', function () {
    const headerLinkId = $(this).closest('.header-link-icon').data('header-link-id');
    $('#create-header-link .modal-title').text('編集');

    $.ajax({
      url: '/header_links/' + headerLinkId + '/info',
      method: 'GET',
      dataType: 'json',
      success: function (res) {
        const headerLink = res.header_link;
        const updateAction = $('.header-link-icon').data('update-action');

        $('#create-header-link .new_header_link').attr('action', updateAction);
        $('#create-header-link .new_header_link').attr('method', 'post');
        $('#create-header-link .new_header_link').prepend(`
          <input type="hidden" name="_method" value="patch">
        `);
        $('#title_header_link').val(headerLink.title);
        $('#url_header_link').val(headerLink.url);
        $('#position_header').val(headerLink.position);
      },
    });

    setTimeout(function () {
      $('#create-header-link').modal('show');
    }, 300);
  });

  function resetCreateModal() {
    $('.help-block').remove();
    $('.has-error').removeClass('has-error');
    $('#title_header_link').val('');
    $('#url_header_link').val('');
    $('input[name=_method]').remove();
    $('#position_header').val('');
  }

  const element = document.querySelector(".header-link");
  element.addEventListener('wheel', (event) => {
    event.preventDefault();

    element.scrollBy({
      left: event.deltaY < 0 ? -30 : 30,
    });
  });
});
