// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

setTimeout(function () {
  $('.js-flash-msg-area').fadeOut('fast');
}, 3000);


$(window).on('resize', function () {
  var win = $(this); //this = window
  if (win.width() < 992) {
    swapWorkerFormBoxs();
    swapWorkerFormFields();
  }
});

function initSidebarMenuState() {
  let current_location = location.href;
  let elements = $('.sidebar').find('.s-item');

  for (let i = 0; i < elements.length; i++) {
    let dropdown_content_items = $(elements[i]).find('.sidebar-dropdown-container ul li');
    let $dropdown_btn = $(elements[i]).find('.sidebar-dropdown-btn');

    for (let j = 0; j < dropdown_content_items.length; j++) {
      let item = dropdown_content_items[j];
      let item_href = $(item).find('a').attr('href');

      if (current_location.indexOf(item_href) != -1) {
        openSidebarItemState($dropdown_btn);

        return false;
      }
    }
  }
}

function swapWorkerFormBoxs() {
  let div1 = $('.form-workers-page .second-box-wrapper');
  let div2 = $('.form-workers-page .third-box-wrapper');

  swapPosition2Boxs(div1, div2);
}

function swapWorkerFormFields() {
  let secondbox_col6_1 = $($('.form-workers-page .second-box-wrapper .row .col-6')[1]);
  let secondbox_col6_2 = $($('.form-workers-page .second-box-wrapper .row .col-6')[2]);

  swapPosition2Boxs(secondbox_col6_1, secondbox_col6_2);

  let secondbox_col6_0 = $($('.form-workers-page .second-box-wrapper .row .col-6')[0]);
  let secondbox_col6_4 = $($('.form-workers-page .second-box-wrapper .row .col-6')[4]);

  secondbox_col6_0.insertBefore(secondbox_col6_4);
}

$(document).on('click', '.js-sp-menu-icon', function (e) {
  e.preventDefault();

  sp_sidebar_open();
});

$(document).on('click', '.overlay, .sp-sidebar-head-close', function (e) {
  e.preventDefault();

  sp_sidebar_close();
});

$(document).on('click', '.workers-page .sp-head-filter-icons .btn-search', function (e) {
  e.preventDefault();

  $filter_workers_area = $(this).parents('.workers-page').find('.filter-workers-area');

  if ($($filter_workers_area).hasClass('d-none')) {
    $($filter_workers_area).removeClass('d-none');
  } else {
    $($filter_workers_area).addClass('d-none');
  }
});

$(document).on('click', '.btn-reset', function (e) {
  var elements = $(this).parents('form .card').find('input');
  var select_elements = $(this).parents('form .card').find('select');

  for (var i = 0; i < elements.length; i++) {
    if (
      elements[i].type == 'text' ||
      elements[i].type == 'email' ||
      elements[i].type == 'password' ||
      elements[i].type == 'number' ||
      elements[i].type == 'tel'
    ) {
      elements[i].value = '';
    }
  }

  for (var i = 0; i < select_elements.length; i++) {
    select_elements[i].value = '';
  }
});

function swapPosition2Boxs(div1, div2) {
  tdiv1 = div1.clone();
  tdiv2 = div2.clone();

  if (!div2.is(':empty')) {
    div1.replaceWith(tdiv2);
    div2.replaceWith(tdiv1);
  }
}

$(document).on('click', '.sidebar-dropdown-btn', function (e) {
  let elements = $(this).parents('.sidebar').find('.sidebar-dropdown-btn').not($(this));

  for (let i = 0; i < elements.length; i++) {
    if ($(elements[i]).hasClass('active')) {
      closeSidebarItemState(elements[i]);
    }
  }

  handleSidebarMenuState($(this));
});

function closeSidebarItemState($object) {
  $($object).removeClass('active');

  $($object).find('img').removeClass('filter-c-blue');
  $($object).find('span.float-end img').addClass('filter-c-chevron-left');
  $($object).find('span.float-end img').removeClass('rotated-180');
  $($object).find('span.float-end img').addClass('rotated-90');

  let dropdownContent = $($object).siblings('.sidebar-dropdown-container')[0];

  $(dropdownContent).hide('slow');
}

function openSidebarItemState($object) {
  $($object).addClass('active');

  $($object).find('img').addClass('filter-c-blue');
  $($object).find('span.float-end img').removeClass('filter-c-chevron-left');
  $($object).find('span.float-end img').removeClass('rotated-90');
  $($object).find('span.float-end img').addClass('rotated-180');

  let dropdownContent = $($object).siblings('.sidebar-dropdown-container')[0];

  $(dropdownContent).show('slow');
}

function handleSidebarMenuState($this) {
  if ($($this).hasClass('active')) {
    $($this).removeClass('active');

    $($this).find('img').removeClass('filter-c-blue');
    $($this).find('span.float-end img').addClass('filter-c-chevron-left');
    $($this).find('span.float-end img').removeClass('rotated-180');
    $($this).find('span.float-end img').addClass('rotated-90');
  } else {
    $($this).addClass('active');

    $($this).find('img').addClass('filter-c-blue');
    $($this).find('span.float-end img').removeClass('filter-c-chevron-left');
    $($this).find('span.float-end img').removeClass('rotated-90');
    $($this).find('span.float-end img').addClass('rotated-180');
  }

  let dropdownContent = $($this).siblings('.sidebar-dropdown-container')[0];

  if ($(dropdownContent).css('display') === 'block') {
    $(dropdownContent).hide('slow');
  } else {
    $(dropdownContent).show('slow');
  }
}

function initDateRangePicker() {
  $('input.singledate').daterangepicker({
    locale: ja_date_locale,
    singleDatePicker: true,
    autoUpdateInput: false,
    autoApply: true,
  });

  $('input.singledate').on('apply.daterangepicker', function (ev, picker) {
    let format = $(this).data('format') || 'YYYY/MM/DD';
    $(this).val(picker.startDate.format(format));
  });

  $('input.singledate').on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
  });

  let names = [
    'daterange',
    'daterange_birthdate',
    'daterange_join_at',
    'daterange_leave_at',
    'daterange_used_at',
    'daterange_submitted_at',
    'ordered_at',
    'sheet_made_at',
    'deposit_date',
    'bill_date',
    'payment_limit_at',
    'daterange_contracted_at',
    'daterange_estimated_deliver_at',
    'daterange_start_at',
    'created_at'
  ];
  names.forEach((name) => {
    initDateRangeByName(name);
  });
}

function sp_sidebar_open() {
  $('#main-body-sidebar').removeClass('d-none');
  $('body').addClass('noscroll');
  $('.overlay').removeClass('d-none');
}

function sp_sidebar_close() {
  $('#main-body-sidebar').addClass('d-none');
  $('.overlay').addClass('d-none');
  $('body').removeClass('noscroll');
}

$(document).on('click', '.js-search-projects-btn', function (e) {
  $('.js-projects-search-btn-submit').trigger('click');
});

$(document).on('click', '.remove_fields', function (event) {
  $(this).closest('.nested-fields').find('.upload-file').remove();
  return checkSizeDataUpload();
});

$(document).on('change', '.upload-file', function (event) {
  $('#remove_file_worker').removeClass('hidden');
  let preview = $(this).closest('.nested-fields').find('.show-file-name');
  let input = $(event.currentTarget);
  let file = input[0].files[0];
  $('.button-file').addClass('d-none');
  preview.removeClass('d-none');
  preview.val(file.name);
  checkSizeDataUpload();
  checkSizeDataUploadWorker();
});

function checkSizeDataUpload() {
  let total_size_data_uploaded = 0;
  $('.upload-file').each(function (event) {
    let upload_file = $(this)[0].files[0];
    let size = upload_file.size;
    total_size_data_uploaded += size;
  });

  if (total_size_data_uploaded / 1000000 > 100) {
    $('#error_limit_file_upload').removeClass('d-none');
    $('#submit_form_new').prop('disabled', true);
  } else {
    $('#error_limit_file_upload').addClass('d-none');
    $('#submit_form_new').prop('disabled', false);
  }
}

function checkSizeDataUploadWorker() {
  let total_size_data_uploaded = 0;
  $('.upload-file').each(function (event) {
    let upload_file = $(this)[0].files[0];
    let size = upload_file.size;
    total_size_data_uploaded += size;
  });

  if (total_size_data_uploaded / 1000000 > 1) {
    $('#error_limit_file_upload').removeClass('d-none');
    $('#submit_form_worker').prop('disabled', true);
  } else {
    $('#error_limit_file_upload').addClass('d-none');
    $('#submit_form_worker').prop('disabled', false);
  }
}

function initSelect2() {
  $('.select2').select2({
    width: '100%',
    theme: 'bootstrap',
    placeholder: '選択してください',
    allowClear: true,
    language: {
      noResults: function () {
        return '見つかりません。';
      },
    },
  });

  // $('.select2_without_placeholder').select2({
  //   width: '100%',
  //   theme: 'bootstrap',
  //   allowClear: true,
  //   language: {
  //     noResults: function () {
  //       return '見つかりません。';
  //     },
  //   },
  // });

  let selectorId = 'assigned_worker_id';

  $('#' + selectorId).attr('multiple', 'multiple');
  if ($('#' + selectorId + ' option')[0]) $('#' + selectorId + ' option')[0].remove();

  $('#' + selectorId).select2({
    multiple: true,
    theme: 'bootstrap',
    placeholder: '選択してください',
    allowClear: true,
    language: {
      noResults: function () {
        return '見つかりません。';
      },
    },
  });

  if ($('.select2').hasClass('has-error')) {
    $('.select2-container--bootstrap .select2-selection--single').addClass('has-error');
  }
}

function initDateRangeByName(name) {
  $(`input[name=${name}]`).daterangepicker({
    locale: ja_date_locale,
    autoUpdateInput: false,
    autoApply: true,
    allowEndDateFirst: true,
  });

  $(`input[name=${name}]`).on('apply.daterangepicker', function (ev, picker) {
    $(this).val(
      picker.startDate.format('YYYY/MM/DD') + ' ~ ' + picker.endDate.format('YYYY/MM/DD')
    );
    var clearIcon = $(this).closest('.input-clearable').find('.clear-icon');
    clearIcon.css('display', this.value ? 'inline' : 'none');
  });

  $(`input[name=${name}]`).on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
  });
}

$(document).on('click', '#sheet_made_at', function (event) {
  $('.drp-buttons').css('display', 'block');
  $('.drp-selected').css('display', 'none');
  $('.applyBtn').css('display', 'none');
  $('.cancelBtn').css('color', '#2674ff');
  $('.cancelBtn').css('background', 'white');
});

$(document).on('click', '#checkAll', function (event) {
  $('.check').prop('checked', $(this).prop('checked'));
});

$(document).on('change', '#model_id', function (event) {
  let maker_id = $('.js-select-maker').find(':selected').text();
  let object_lease_type_id = $('#object_lease_type_id').find(':selected').text();
  let model_id = $('.js-select-model').find(':selected').text();
  $('#name').prop('readonly', true);
  $('#name').val(maker_id + object_lease_type_id + model_id);
});

$(document).on('click', '.add_fields', function (event) {
  // set timeout to wait for rails to add the new fields
  setTimeout(function () {
    initDateRangePicker();
    initSelect2();
    if($('#project_id').val() != '') { 
      $('.order_sheet_project_id .select2-container--bootstrap .select2-selection--single').css("background", "#dddddd")
    }
  }, 50);
  const element = document.getElementById('add_file_worker');
  if (element) {
    $('.add_fields').addClass('hidden');
    $('.upload-file').click();
    $('#remove_file_worker').addClass('hidden');
  }
});

$(document).on('click', '.remove_fields', function (event) {
  const element = document.getElementById('remove_file_worker');
  const nested_fields = $(event.target).closest('.nested-fields');
  if (element) {
    $('.add_fields').removeClass('hidden');
  } else if (nested_fields.length > 0) {
    $(nested_fields).hide();
  }
});

$(document).on('click', '.btn-edit-file', function (e) {
  const fileId = $(this).closest('.upload-item').children('.file-id');
  const fileName = $(this).closest('.upload-item').children('.file-name');
  const fileInput = $(this).closest('.upload-item').children('.file-name-input');
  if (fileName.length > 0) {
    const value = fileName.html();
    fileName.replaceWith(`<input class="form-control file-name-input" value="${value}">`);
  } else {
    const value = fileInput.val();
    fileInput.replaceWith(`<span class='file-name'>${value}</span>`);
  }
  if (fileInput.val()) {
    $.ajax({
      url: '/estimate_sheets/update_file_name',
      method: 'PUT',
      data: { file_id: fileId.text(), file_name: fileInput.val() },
      success: function (res) {
        // window.location = '/estimate_sheets';
      },
    });
  }
});

$(document).on('change', '.upload-files', function (event) {
  let parent = $(this).closest('.text-body');
  let input = $(event.currentTarget);
  let files = input[0].files;
  for (let i = 0; i < files.length; i++) {
    let obj = files[i];
    parent.prepend(
      `<div class="nested-fields expense-file-inline ${
        obj.lastModified
      }"><div class="d-flex"><a target="_blank" href="#"> ${obj.name.substring(
        0,
        10
      )}</a><input value="false" type="hidden" name="expense[accept_files][${i}][_destroy]" id="expense_files_${i}__destroy"><a class="ms-3 close remove_fields existing" href="#" data-remote="true"><span aria-hidden="true">×</span></a></div></div>`
    );
  }
});

$('input.singledate').on('apply.daterangepicker', function (ev, picker) {
  let format = $(this).data('format') || 'YYYY/MM/DD';
  $(this).val(picker.startDate.format(format));
});

$(document).on('change', "input[data-toggle-accepting]", function (event) {
  $($(event.target).closest('.wrap-expand-arrow').find('div[data-accepting-area]').first()).toggleClass('hidden')
})

$(document).on('change', "[data-expired-at]", function (event) {
  $($(event.target).closest('.wrap-expand-arrow').find('div[data-accepting-area]').first()).toggleClass('hidden')
})

$(document).on('click', "#choose-ordered-item-submit", function (event) {
  event.preventDefault();
  var isInvalid = false;
  var $productName = $('#modal-choose-ordered-item .js-product-text');
  var $productQuantity = $('#modal-choose-ordered-item .js-amount');
  var $productUnitPrice = $('#modal-choose-ordered-item .js-unit');
  var $contractStartDate = $('#modal-choose-ordered-item #contract_start_date');

  if ($productName.val() == '') {
    isInvalid = true;
    $productName.addClass('is-invalid');
    $('#modal-choose-ordered-item .error-message').removeClass('hidden');
  }

  if ($contractStartDate.val() == '') {
    isInvalid = true;
    $contractStartDate.addClass('is-invalid');
    $('#modal-choose-ordered-item .error-message').removeClass('hidden');
  }

  // if ($productQuantity.val().length == 0) {
  //   isInvalid = true;
  //   $productQuantity.addClass('is-invalid');
  //   $('#modal-choose-ordered-item .error-message').removeClass('hidden');
  // }

  if ($productUnitPrice.val() == '') {
    isInvalid = true;
    $productUnitPrice.addClass('is-invalid');
    $('#modal-choose-ordered-item .error-message').removeClass('hidden');
  }

  if (isInvalid) {
    return false;
  }
  else {
    $(event.target).closest('#modal-choose-ordered-item').find('form').submit()
  }
})

// $(document).on('click', "#modal-search-product .btn-choose-product", function (event) {
//   event.preventDefault();
//   if ($('.edit-project-form').length == 0) {
//     return false
//   }

//   const productId = $(this).val();
//   $.ajax({
//     url: '/ordered_items/new',
//     method: 'GET',
//     data: { product_id: productId, project_id: $('#ordered_item_project_id').val() },
//     success: function (res) {
//       $('#modal-search-product').modal("hide");
//     },
//   });
// });
function calculateFees() {
  let total = 0;
  let total_free_tax = 0;

  let value;
  $('.nested-fields:not(:hidden) .js_total_price').each(function () {
    value = parseInt(this.value) || 0;
    total += value;

    if (this.hasAttribute('data-free-tax') && $(this).data('free-tax')) {
      total_free_tax += value;
    }
  });

  const total_fee = Math.round((total - total_free_tax) * 0.1 + total);
  return { total, total_fee };
}

function calcBillItemsPrice() {
  const { total, total_fee } = calculateFees();
  var total_fee_without_tax = '￥ ' + total.toLocaleString()
  var total_fee_display = '￥ ' + total_fee.toLocaleString()
  $('.js-total-amount').html(total_fee_without_tax);
  $('.js-total_fee_without_tax_pr_coninue').html(total_fee_without_tax);
  $('.ordered_item_total_without_tax').data('value', total)

  $('.js-total-amount-with-tax').html(total_fee_display);
  $('.js_total_fee_pr_continue').html(total_fee_display);
  if ($('#bill_sheet_is_pay_full').prop('checked') == true){
    $('#bill_sheet_total_fee_without_tax').val(total);
  }

  $('#bill_sheet_total_fee').val(total_fee);
  calcItemTotalFee()
  // let ordered_item_total_with_tax = parseInt($('.ordered_item_total_with_tax').data('value'));
  // if (total > ordered_item_total_with_tax) {
  //   $('.bill-error-msg').removeClass('d-none')
  //   $("#modal-bill-sheet-submit").attr('disabled', true)
  // } else {
  //   $('.bill-error-msg').addClass('d-none')
  //   $("#modal-bill-sheet-submit").attr('disabled', false)
  // }
}
window.calcBillItemsPrice = calcBillItemsPrice

$(document).on('change', '.js-bill-items .js-amount, .js-bill-items .js-price, .js-bill-items .js-amount', function (event) {
  let amount = $(this).closest('.nested-fields').find('.js-amount').val() || 0
  let price = $(this).closest('.nested-fields').find('.js-price').val() || 0
  let total = parseInt(amount) * parseInt(price)
  $totalPrice = $(this).closest('.nested-fields').find('.js-total-price span')

  $totalPrice.html('￥ ' + total.toLocaleString());
  $totalPrice.attr('data-value', total);
  $(this).closest('.nested-fields').find('.js_total_price').val(total)
  calcBillItemsPrice()
})

$(document).on('input', '#ordered_item_unit_price', function (event) {
  update_selling_price(event);
});

function update_selling_price(event) {
  let ordered_item_quantity = parseInt($('#ordered_item_quantity').val().replace(/,/g, ''), 10)
  let ordered_item_unit_price = parseInt($(event.target).closest('form').find('.js-unit-price').val().replace(/,/g, ''));
  let total = ordered_item_quantity * ordered_item_unit_price;
  $(event.target).closest('form').find('.js-selling-price').html(total.toLocaleString());
}

$(document).on('click', '[data-go-order-screen]', function (event) {
  const companyId = $('#choose_company_id').val();
  const projectId = $('[data-project-id]').first().attr('data-project-id')
  const acceptingOrderIds = $("#modal-choose-order").find(".checkbox-estimate-item:checked").map(function (e) {
    return $(this).data('id')
  });

  if (companyId.length == 0) {
    $(this).closest('.modal-body').find('.error-msg').removeClass('d-none')
    return false;
  } else {
    $(this).closest('.modal-body').find('.error-msg').addClass('d-none')
  }

  location.href = `/order_sheets/new?project_id=${projectId}&company_id=${companyId}&ordered_item_ids=${acceptingOrderIds.toArray().join(',')}`
});


$(document).on('shown.bs.modal', '#modal-choose-order', function () {
  $("#modal-choose-order").find(".toggle-check-all-object").prop('checked', true);
  $("#modal-choose-order").find(".checkbox-estimate-item").prop('checked', true);
})

$(document).on('hide.bs.modal', '#modal-choose-order', function () {
  $('#modal-choose-order').find('.error-msg').addClass('d-none')
  $('#modal-choose-order').find('#choose_company_id').val('').change();
})

window.addEventListener('DOMContentLoaded', function () {
  $('.js-disabled-input-and-button input').prop('disabled', true);
  $('.js-disabled-input-and-button select').prop('disabled', true);
  $('.js-disabled-input-and-button button').addClass('disabled');

  initDateRangePicker();
  initDateRangePickerMonthYear();
  initSelect2();
  const element = document.getElementById('show-file-name');
  if (element) {
    $('.add_fields').addClass('hidden');
  }

  let screen_width = $(window).width();
  if (screen_width < 992) {
    swapWorkerFormBoxs();
    swapWorkerFormFields();
  }

  initSidebarMenuState();
});

$(document).on('change', '.convert-half-width', function (event) {
  let value = convertToHalf(parseFloat($(this).val().replace(/,/g, '')).toString());
  $(this).val(value.toLocaleString())
  if ($('.esimate-sheet-page').length > 0) {
    setTimeout(() => {
      estimateSheetCalcTotal()
    }, 300);
  }
  if($(this).attr('id') == 'ordered_item_quantity') {
    update_selling_price(event);
  }
})

function convertToHalf(message) {
  return Number(message.replace(/[！-～]/g, halfwidthChar => String.fromCharCode(halfwidthChar.charCodeAt(0) - 0xfee0))) || '';
}

$(document).on('click', '.bill-sheet-collapse', function (e) {
  if ($(this).data('showing')) {
    $(this).find('.arrow-up').removeClass('d-none');
    $(this).find('.arrow-down').addClass('d-none');
    $(this).data('showing', false)
    $('#bill-items').addClass('d-none');
  } else {
    $(this).find('.arrow-up').addClass('d-none');
    $(this).find('.arrow-down').removeClass('d-none');
    $(this).data('showing', true)
    $('#bill-items').removeClass('d-none');
  }
})

function calcItemTotalFee() {
  let total = Math.round(parseInt($('#bill_sheet_total_fee_without_tax').val()) * 1.1) || 0;
  $('.js-total_fee').html('￥ ' + (total).toLocaleString());
  $('#bill_sheet_total_fee').val(total)
}

$(document).on('change', '#bill_sheet_is_pay_full', function () {
  let oldValue = $('#bill_sheet_total_fee_without_tax').data('value') || 0
  let remainVal = $('.ordered_item_total_without_tax').data('value') || 0

  if ($(this).prop('checked')) {
    if(oldValue > 0) {
      $('#bill_sheet_total_fee_without_tax').val(parseInt(oldValue) + parseInt(remainVal))
    } else {
      const { total } = calculateFees();
      $('#bill_sheet_total_fee_without_tax').val(total)
    }

    $('#bill_sheet_total_fee_without_tax').attr('readonly', true)
  } else {
    $('#bill_sheet_total_fee_without_tax').val(oldValue)
    $('#bill_sheet_total_fee_without_tax').attr('readonly', false)
  }
  calcItemTotalFee()
})

$(document).on('change', '#bill_sheet_total_fee_without_tax', function () {
  calcItemTotalFee()
})

$(document).on('click', "#modal-bill-sheet-submit", function (event) {
  event.preventDefault();
  const form = $(event.target).closest('#modal-create-bill-sheet').find('form');
  var formData = $(form).serialize();

  $.ajax({
    url: $(form).attr('action'),
    type: 'POST',
    data: formData,
    dataType: 'json',
    
    success: function(response) {
      const url = new URL(window.location.href);
      url.searchParams.set('current_tab', 'bill-tab');
      window.location.href = url.toString(); 
    },
    
    error: function(xhr, status, error) {
      if(JSON.parse(xhr.responseText)["errors"].sending_place[0] != undefined) {
        $('#sending-place-error').html(JSON.parse(xhr.responseText)["errors"].sending_place[0]).removeClass('d-none');
      }
    }
  });

})


$(document).on('change', '.money-field', function() {
  var value_input = $(this).val();
  if(value_input != '') {
    value_input = value_input.replace(',', '');
  }
  if(isNumeric(value_input)) {
    $(this).val(value_input.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
  } else {
    $(this).val(0);
  }
})

function isNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str))
}

$(document).on('select2:select', function (e) {
  let values = $(e.target).val();
  if (!Array.isArray(values)) {
    return
  }

  if (values.includes('')) {
    values = values.filter(function (value) {
      return value !== '';
    });
    $(e.target).val(values).change();
  }
})
$(document).on('click', '.multiple-upload-file-editable .btn-done', function (e) {
  e.preventDefault();
  $container = $(this).closest('.file')
  $container.find('.btn-edit').removeClass('d-none')
  $container.find('.btn-delete').removeClass('d-none')
  $container.find('.view-input-file-name').removeClass('d-none')
  $container.find('.btn-done').addClass('d-none')
  $container.find('.input-file-name').addClass('d-none')
})

$(document).on('click', '.multiple-upload-file-editable .btn-edit', function (e) {
  e.preventDefault();
  $container = $(this).closest('.file')
  $container.find('.btn-edit').addClass('d-none')
  $container.find('.btn-delete').addClass('d-none')
  $container.find('.btn-done').removeClass('d-none')
  $container.find('.view-input-file-name').addClass('d-none')
  $container.find('.input-file-name').removeClass('d-none')
})

$(document).on('change', '.multiple-upload-file-editable .input-file-name', function (e) {
  new_values = []
  value = $(this).val()
  var id = $(this).data('id')

  $(this).closest('.file').find('.view-input-file-name').html(value)
  $('.multiple-upload-file-editable .input-file-name').each(function (index, element) {
    value = $(this).val()
    old_file_name = $(this).attr('data-old-filename')
    if (value.length != 0 && value !== old_file_name) {
      new_values.push(`${id}_${old_file_name} -> ${value}`)
    }
  })
  $(this).closest('.tab-pane').find('.update_file_names').val(new_values.join(','))
})

$(document).on('click', '.multiple-upload-file-editable .btn-delete', function (e) {
  e.preventDefault();
  $container = $(this).closest('.file')
  $container.find("[type='checkbox']").prop('checked', true);
  $(this).closest('.file').addClass('d-none');
})

function createTempfile(file) {
  formData = new FormData();
  formData.append("file[]", file, file.name);

  $.ajax({
    url: '/attachments/upload_tempfile',
    method: 'POST',
    body: formData,
    success: function (res) {
      return res;
    },
  });
}

$(document).on('change', '.multiple-upload-file-editable .multiple-upload-file', function (e) {
  var files = this.files;
  $container = $(this).closest('.multiple-upload-file-editable')
  $listFile = $container.find('.list-files')
  $newFilesAdded = $listFile.find('.isNewFile')
  // $newFilesAdded.remove()

  if (files.length > 10) {
    alert('アップロードは10ファイルまで入力してください。');
      $(this).val('');
    return false;
  } else {
    var file;
    for (var i = 0; i < files.length; i++) {
      file = files[i];
      if (file.size > 10 * 1024 * 1024) { // 10MB
        alert('各ファイルのサイズは10MBを超えてはならない。');
        $(this).val('');
        $listFile.html('');
        break;
      } else {
        file_name = file.name
        $containerTemp = $container.find('[data-file-templete]')
        $containerTemp.find('.add_fields')[0].click();
        // $fileTemplete = $containerTemp.find('.file');
        $fileTemplete = $container.find('.isCreating');

        var tempId = $containerTemp.attr('id');
        $fileTemplete.find('.input-file-name').attr('value', file_name).attr('data-old-filename', file_name)
        $fileTemplete.find('.view-input-file-name').html(file_name)
        $fileTemplete.find('.btn-preview').attr('href', (window.URL || window.webkitURL).createObjectURL(file));
        $remote_file_url = $fileTemplete.find('.remote_file_url')
        // add random string to file
        new_id = Math.random().toString(36).substring(7)
        $remote_file_url.attr('id', new_id)
        $fileTemplete.removeClass('isCreating').addClass('isNewFile') // add isNewFile class to new file
        formData = new FormData();
        formData.append("file", file, file.name);
        $.ajax({
          url: '/attachments/upload_tempfile?selector_id=' + new_id,
          method: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          success: function (response) {
            old_delete_tempfiles = window.delete_tempfiles_val || [];
            old_delete_tempfiles.push(response.path)
            window.delete_tempfiles_val = old_delete_tempfiles;
            $('#delete_tempfiles').val(old_delete_tempfiles.join(','))

            // $("#" + response.selector_id).val(`https://c579-2406-da14-ad6-2e00-e454-be87-14f6-263a.ngrok-free.app/${response.path}`);
            $("#" + response.selector_id).val(location.protocol + "//" + location.host + response.path);
          },
          error: function (xhr, status, error) {
            console.error(error);
          }
        });
        // $listFile.append($fileTemplete.prop('outerHTML'));
        // $fileTemplete.remove();
      }
    }
  }
  $(this).val('');
});

$(document).on('click', '#showDialogImportKorabo', function (e) {
  e.preventDefault();
  $("#modalImportKorabo").modal('show')
})

$(document).on('click', '#modalImportKoraboError .btn-override_bill_sheet', function (e) {
  if ($(this).attr('data') == 'bill_sheets_exist') {
    e.preventDefault();
    $('#korabo_override_bill_sheet').val('true')
    $('form #modalImportKorabo input[type="submit"]').click()
  }
})

function koraboEnableSubmitUploadBtn() {
  $('#korabo_override_bill_sheet').val('false')
  if($('#korabo_csv_path')[0].files.length > 0 && $('#korabo_date').val().length > 0) {
    $('.btn-submit-korabo').prop('disabled', false)
  } else {
    $('.btn-submit-korabo').prop('disabled', true)
  }
}

$(function () {
  $('#korabo_date').on('apply.daterangepicker', function (ev, picker) {
    koraboEnableSubmitUploadBtn()
  })
})

$(document).on('change', '#korabo_csv_path', function (e) {
  $('.file-choosed').html(this.files[0].name);
  koraboEnableSubmitUploadBtn()
})

$(document).on('hidden.bs.modal', '#modalImportKorabo', function (e) {
  $('#korabo_csv_path').val('');
  $('#korabo_date').val('');
  $('.file-choosed').html('');
  $('.simple_form.korabo input[type="submit"]').prop('disabled', true);
  $('#korabo_override_bill_sheet').val('false')
});

$(document).on('click', '.btn-copy', function (e) {
  e.preventDefault();
  copy_content = $(this).data('copy-content');
  var $temp = $("<input id='copyInput' class='d-none'>");
  $("body").append($temp);
  $temp.val(copy_content)
  var copyText = document.getElementById("copyInput");

  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices
  navigator.clipboard.writeText(copyText.value);
  $temp.remove();
})

$(document).ready(function() {
  $(document).on('mousedown', '.select2-container', function(e) {
    e.stopPropagation();
  });

  function focusNextInput(currentElement) {
      var $inputs = $('.form-enter-tab input, .form-enter-tab select, .form-enter-tab button').filter(':visible').not(':disabled');
      var currentInputIndex = $inputs.index(currentElement);

      if (currentInputIndex + 1 < $inputs.length) {
          var nextElement = $inputs.eq(currentInputIndex + 1);

          // Prevent select2 from opening on focus
          if (nextElement.hasClass('select2-hidden-accessible')) {
            if ($('.select2-container--open').length > 0) {
              if ($('.select2-results__option').length === 0) {
                  $(this).select2('close');
              }
            }
            nextElement.on('select2:opening', function(e) {
                e.preventDefault();
            });

            setTimeout(function() {
              nextElement.off('select2:opening');
            }, 100);
          }

          nextElement.focus();

          if (nextElement.is(':radio')) {
              nextElement.prop('checked', true);
          }
      } else {
          $inputs.eq(0).focus();
      }
  }

  // $(document).on('select2:opening', '.form-enter-tab select', function(e) {
  //     if ($(this).data('prevent-open')) {
  //         $(this).removeData('prevent-open');
  //         e.preventDefault();
  //     }
  // }).on('focus', '.form-enter-tab select', function(e) {
  //     if ($(this).hasClass('select2-hidden-accessible')) {
  //         $(this).data('prevent-open', true);
  //     }
  // });

  $(document).on('keypress', '.form-enter-tab input, .form-enter-tab select, .form-enter-tab button', function(event) {
      if (event.which == 13) {
        event.preventDefault();
        if ($(this).hasClass('select2-hidden-accessible')) {
          console.log(123);
          var select2Element = $(this);
          var hasOptions = select2Element.children('option').length > 0;
          if (!hasOptions) {
            focusNextInput(this);
            return;
          }
        }

        focusNextInput(this);
      }
  });

  $(document).on('keydown', '.form-enter-tab input[type=checkbox], .form-enter-tab input[type=radio]', function(event) {
      if (event.which == 13) {
          event.preventDefault();
          focusNextInput(this);
      }
  });

  $(document).on('select2:selecting', '.form-enter-tab select', function(e) {
      setTimeout(() => focusNextInput(this), 0);
  });

  $(document).on('select2:close', '.form-enter-tab select', function(e) {
      focusNextInput(this);
  });

  $(document).on('keydown', '.form-enter-tab select', function(e) {
      if (e.which == 13) {
          e.preventDefault();
          focusNextInput(this);
      }
  });
  
  $(document).on('keypress', '.form-enter-tab input[type=checkbox], .form-enter-tab input[type=radio], .form-enter-tab select', function(event) {
    if (event.which == 13) {
        event.preventDefault();
        focusNextInput(this);
      }
  });

  $(document).on('change', '.form-enter-tab select:not(.select2-hidden-accessible)', function() {
      focusNextInput(this);
  });

  $(document).on('shown.bs.modal', '#modal-choose-ordered-item', function () {
    update_datepicker();
  })

  $(document).on('shown.bs.modal', '#modal-create-bill-sheet', function () {
    update_datepicker();
  })

  $(document).on('shown.bs.modal', '#problem-client-comapny-form', function () {
    update_datepicker();
  })  

  function update_datepicker() {
    $('.datepicker-js').on('apply.daterangepicker', function(ev, picker) {    
      $(this).val(picker.startDate.format('YYYY/MM/DD'));
      var clearIcon = $(this).closest('.input-clearable').find('.clear-icon');
      clearIcon.css('display', this.value ? 'inline' : 'none');
    });
  }
});

function initDateRangePickerMonthYear() {
  $('input.singledate-month-year').daterangepicker({
    locale: ja_date_locale_month_year,
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    isCustomDate: function() {
      $('.daterangepicker:visible .single.drp-calendar').css("width", "100%");
      $('.daterangepicker:visible .single .monthselect').css({
        "width": "45%",
        "float": 'right'
      });
      if($('.daterangepicker:visible .single .datepicker-year').length == 0){
        $('.daterangepicker:visible .single .monthselect').after("<div class='datepicker-year', style='float:right;'>年</div>");
      }
      $('.daterangepicker:visible .yearselect').css({
        "width": "45%",
        "float": 'right'
      });
      $('.daterangepicker:visible .single .table-condensed > tbody').hide();
      $('.daterangepicker:visible .single .table-condensed > thead').children('tr').eq(1).hide();
    }
  });

  $('input.singledate-month-year').on('hide.daterangepicker', function (ev, picker) {
    let month = parseInt($('.monthselect :selected').val()) + 1;
    let year = $('.yearselect :selected').val();
    let format = $(this).data('format') || 'YYYY/MM/DD';
    picker.setStartDate(year + '/' + month);
    $('input.singledate-month-year').val(picker.startDate.format(format));
    var clearIcon = $(this).closest('.input-clearable').find('.clear-icon');
    clearIcon.css('display', this.value ? 'inline' : 'none');
  });

  $('input.singledate-month-year').on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
  });

  $('input.singledate-month-year').on('show.daterangepicker', function (ev, picker) {
    $('.daterangepicker:visible .single.drp-calendar').css("width", "100%");
    $('.daterangepicker:visible .single .monthselect').css({
      "width": "45%",
      "float": 'right'
    });
    if($('.daterangepicker:visible .single .datepicker-year').length == 0){
      $('.daterangepicker:visible .single .monthselect').after("<div class='datepicker-year', style='float:right;'>年</div>");
    }
    $('.daterangepicker:visible .single .yearselect').css({
      "width": "45%",
      "float": 'right'
    });
    $('.daterangepicker:visible .single .table-condensed > tbody').hide();
    $('.daterangepicker:visible .single .table-condensed > thead').children('tr').eq(1).hide();
    $('.daterangepicker .single').on('change', '.yearselect', function () {
      let month = parseInt($('.monthselect :selected').val()) + 1;
      let year = $('.yearselect :selected').val();
      let format = 'YYYY/MM';
      picker.setStartDate(year + '/' + month);
      $('input.singledate-month-year').val(picker.startDate.format(format));
      var clearIcon = $(this).closest('.input-clearable').find('.clear-icon');
      clearIcon.css('display', this.value ? 'inline' : 'none');
    }) 
    $('.daterangepicker .single').on('change', '.monthselect', function () {
      let month = parseInt($('.monthselect :selected').val()) + 1;
      let year = $('.yearselect :selected').val();
      let format = 'YYYY/MM';
      picker.setStartDate(year + '/' + month);
      $('input.singledate-month-year').val(picker.startDate.format(format));
      var clearIcon = $(this).closest('.input-clearable').find('.clear-icon');
      clearIcon.css('display', this.value ? 'inline' : 'none');
    }) 
  });
}