$(document).on('click', '.bank-index-page .btn-edit', function () {
  let $container = $(this).closest('tr')
  $container.find('.btn-edit').addClass('d-none');
  $container.find('.btn-save').removeClass('d-none');
  let branchCode = $container.find('.branch-code').html()
  $container.find('.branch-code').html(`<input class="form-control" type="text" value='${branchCode}'>`)
  let branchName = $container.find('.branch-name').html()
  $container.find('.branch-name').html(`<input class="form-control" type="text" value='${branchName}'>`)
})

$(document).on('click', '.bank-index-page .remove-dynamic-field', function () {
  let isNewRecord = $(this).data('new-record')
  if (isNewRecord == true) {
    if ($(this).data('back-url') == 'reload') {
      $(this).closest('tr').remove()
    } else {
      $(this).closest('.row').remove()
    }
  } else {
    $(this).closest('.row').find('_destroy').val('1')
    $(this).closest('.row').hide()
  }
})
$(document).on('click', '.bank-index-page .btn-save', function () {
  let $container = $(this).closest('tr')
  let branchId = $container.data('branch-id')
  let bankId = $container.data('bank-id')
  let branchName = $container.find('.branch-name').find('input').val()
  let branchCode = $container.find('.branch-code').find('input').val()

  if (branchName.length == 0) {
    $container.find('.branch-name').append('<span class="help-block text-danger small">このフィールドは必須です。</span>')
  } else {
    $container.find('.branch-name').find('.help-block').remove()
  }

  if (branchCode.length == 0) {
    $container.find('.branch-code').append('<span class="help-block text-danger small">このフィールドは必須です。</span>')
  } else {
    $container.find('.branch-code').find('.help-block').remove()
  }

  if (branchName.length == 0 || branchCode.length == 0) {
    return
  }

  $.ajax({
    url: `/banks/${bankId}?type=create_branch`,
    type: 'PUT',
    data: {
      bank: { id: bankId, branches_attributes: { id: branchId, branch_name: branchName, code: branchCode } }
    },
    success: function (e) {
      location.reload()
    }
  })
})
